import { useCallback, useMemo, useState } from 'react';
import { MenuSet, MenusProps, ProductType } from '../../../common/types/menu';
import {
  calcRecipeNutrientsDefault,
  calcTotalRecipeNutrients,
  formatProduct,
  formatRecipe,
} from '../utils';
import { useFetchProductsByIds } from '../../../requests/products/useFetchProductsByIds';
import { ProductsPropsWithDbId } from '../../../common/types/products';
import { useFetchRecipesByIds } from '../../../requests/recipes/useFetchRecipesByIds';
import { RecipeProps } from '../../../common/types/recipes';
import { useQueryClient } from '@tanstack/react-query';
import { MUTATION_KEYS } from '../../../requests/constants';
import { ResponseCustom } from '../../../requests/types';

type UseFormatMenu = ({ menuData }: { menuData: MenusProps }) => {
  menuSet: MenuSet[];
  loading: boolean;
};

export const useFormatMenuSet: UseFormatMenu = ({ menuData }) => {
  const [menuSet, setMenuSet] = useState<MenuSet[]>([]);

  const activeMenuId = menuData._id;

  const queryClient = useQueryClient();
  const recipesQueryKey = `${MUTATION_KEYS.MUTATE_RECIPES_BY_IDS}-${activeMenuId}`;
  const productsQueryKey = `${MUTATION_KEYS.MUTATE_PRODUCTS_BY_IDS}-${activeMenuId}`;
  const recipesStore: ResponseCustom<RecipeProps[]> | undefined =
    queryClient.getQueryData([recipesQueryKey]);
  const productsStore: ResponseCustom<ProductsPropsWithDbId[]> | undefined =
    queryClient.getQueryData([productsQueryKey]);

  const { fetchRecipesByIds } = useFetchRecipesByIds();
  const { fetchProductsByIds } = useFetchProductsByIds();

  const getStockProducts = useCallback(
    async (productsIds: Record<string, string>) => {
      const productsIdsArr = Object.keys(productsIds);

      if (productsIdsArr.length) {
        const response =
          productsStore ??
          (await fetchProductsByIds(productsIdsArr, {
            onSuccess: (response) => {
              queryClient.setQueryData([productsQueryKey], response);
            },
          }));

        if (response.data?.length) {
          return response.data.reduce((acc, item) => {
            return {
              ...acc,
              [item._id]: item,
            };
          }, {} as Record<string, ProductsPropsWithDbId>);
        }
      }
    },
    [fetchProductsByIds, productsQueryKey, queryClient, productsStore]
  );

  useMemo(async () => {
    const productsIds: Record<string, string> = {};
    const recipes: Record<string, RecipeProps> = {};

    const recipesIdsString = menuData.menu
      .map((item) =>
        item.products.filter(
          (product) => product.productType === ProductType.recipe
        )
      )
      ?.flat()
      .map((item) => item.productId)
      .join(',');

    if (recipesIdsString?.length) {
      const recipesResponse =
        recipesStore ??
        (await fetchRecipesByIds(recipesIdsString, {
          onSuccess: (response) => {
            queryClient.setQueryData([recipesQueryKey], response);
          },
        }));

      if (recipesResponse.data?.length) {
        recipesResponse.data.forEach((recipe) => {
          recipes[recipe._id] = recipe;
        });

        recipesResponse.data.forEach((recipe) => {
          recipe.products.forEach((product) => {
            productsIds[product.prodId] = product.prodId;
          });
        });
      }
    }

    menuData.menu
      .map((item) =>
        item.products.filter(
          (product) => product.productType === ProductType.product
        )
      )
      ?.flat()
      .forEach((item) => {
        productsIds[item.productId] = item.productId;
      });

    const stockProducts = await getStockProducts(productsIds);

    if (stockProducts) {
      const menuSet = menuData.menu.map(({ day, products }) => {
        return {
          day,
          products: products.map(
            ({
              internalId,
              productId,
              productType,
              weight,
              productDescription,
              order,
              mealtimes,
            }) => {
              if (productType === ProductType.recipe && recipes) {
                const recipe = recipes[productId];
                const recipeProducts = recipe?.products.map(
                  (item) => stockProducts[item.prodId]
                );

                const totalNutrients = calcTotalRecipeNutrients({
                  stockProducts: recipeProducts,
                  recipe,
                });

                const defaultNutrientsData = calcRecipeNutrientsDefault({
                  totalNutrients,
                });

                const formattedRecipe = formatRecipe({
                  internalId,
                  recipe,
                  order,
                  defaultNutrientsData,
                  weight,
                });

                formattedRecipe.weight = weight;
                formattedRecipe.mealtimes = mealtimes;
                formattedRecipe.productDescription = productDescription;

                return formattedRecipe;
              } else {
                const formattedProduct = formatProduct({
                  internalId,
                  order,
                  product: stockProducts[productId],
                  weight,
                });

                formattedProduct.weight = weight;
                formattedProduct.mealtimes = mealtimes;
                formattedProduct.productDescription = productDescription;

                return formattedProduct;
              }
            }
          ),
        };
      });
      setMenuSet(menuSet);
    }
  }, [
    menuData.menu,
    fetchRecipesByIds,
    getStockProducts,
    queryClient,
    recipesQueryKey,
    recipesStore,
  ]);

  return {
    menuSet,
    loading: false,
  };
};
