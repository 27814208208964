import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { fetchProductsByIds } from './requests';
import { ResponseCustom } from '../types';
import { ProductsPropsWithDbId } from '../../common/types/products';

type UseFetchProductsByIds = () => {
  fetchProductsByIds: UseMutateAsyncFunction<
    ResponseCustom<ProductsPropsWithDbId[]>,
    unknown,
    string[]
  >;
  loading: boolean;
};

export const useFetchProductsByIds: UseFetchProductsByIds = () => {
  const { mutateAsync, status } = useMutation<
    ResponseCustom<ProductsPropsWithDbId[]>,
    unknown,
    string[]
  >({
    mutationFn: (productIds) => fetchProductsByIds(productIds),
  });

  return {
    fetchProductsByIds: mutateAsync,
    loading: status === 'pending',
  };
};
